<template>
  <div class="overlay">
    <div class="confirm-dialog">
      <p class="dialog-message">本当にチャットを削除しますか？</p>
      <button @click="closeDialog">キャンセルする</button>
      <button class="delete-button" @click="deleteChat">削除する</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConfirmDialog",
  methods: {
    // チャット履歴削除画面を閉じる
    closeDialog() {
      // 「App.vue」の@closeイベントを発火させて、表示フラグをfalseにする
      this.$emit("close");
    },
    // チャット履歴削除画面を閉じて、選択中のセッションIDに保存されているデータをCosmosDBから削除
    deleteChat() {
      // 「App.vue」の@delete-chatイベントを発火させて、セッションIDのデータをCosmosDBから削除
      // 実装
      // 1. ゴミ箱ボタン押下時に「SideBar.vue」でチャットセッションIDを「App.vue」に渡して変数にバックアップ
      // 2. 「削除ボタン」押下で「App.vue」で作成する削除メソッドを呼び出して、セッションIDに保存されているデータを削除後、
      // 最新のデータをとってきてページを更新する。
      this.$emit("delete-chat");
      this.closeDialog();
    },
  },
};
</script>

<style scoped>
/* 履歴確認画面の黒背景 */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
}

/* チャット履歴削除画面 */
.confirm-dialog {
  background: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

/* 確認画面中のボタン類 */
button {
  /* ボタン間のマージンを設定 */
  margin: 0 30px;
  /* ボタンにパディングを追加 */
  padding: 10px 15px;
  /* デフォルトのボーダーを削除 */
  border: none;
  /* ボタンの角を丸くする */
  border-radius: 5px;
  /* カーソルをポインタに */
  cursor: pointer;
}

/* チャット履歴削除画面メッセージ */
.dialog-message {
  /* ボタンとの間隔を調整 */
  margin-bottom: 30px;
  /* テキストを中央揃え */
  text-align: center;
}

/* 削除するボタン */
.delete-button {
  background-color: red;
  color: white;
}
</style>
