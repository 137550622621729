import { PublicClientApplication } from "@azure/msal-browser";

const msalConfig = {
    auth: {
        // Azure ADで取得したクライアントID(SBC環境)
        clientId: "bc0aa554-f094-48ef-b0fa-192af01ff1e2",
        // テナントID(SBC環境)
        authority: "https://login.microsoftonline.com/4f6afd17-0abe-4158-aa7d-dde358e01d3f",
        // リダイレクトURI(SBC、Azure環境、認証完了後に遷移するページURL)
        redirectUri: "https://l5gtest.otameshi-test.jp",
    },
};

const msalInstance = new PublicClientApplication(msalConfig);

export default msalInstance;